import dayjs from 'dayjs';
import { CommonConstants } from '../CommonConstants';
import { ContentItem } from '@exporter-services/common-ui';

export const redirectTo = (url: string) => {
    document.location.href = url;
    return false;
};

export const capitalise = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

export function strPadLeft(string: string | number, pad: string, length: number): string {
    return (new Array(length + 1).join(pad) + string).slice(-length);
}

export const removeLastTwoChar = (code: string) => code.slice(0, -2);

export function replaceAll(str, find, replace) {
    return str?.replace(new RegExp(find, 'g'), replace);
}

const filterByUrlSector = (filteredData: ContentItem[], urlSector: string) => {
    return filteredData.filter((item) => {
        const kenticoSector = item.elements.industrysectorcodesnippet__sector
            ? item.elements.industrysectorcodesnippet__sector
            : item.elements.industry_sector;

        if (!kenticoSector) return true;

        for (let i = 0; i < kenticoSector?.value.length; i++) {
            let codeName = !kenticoSector ? CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME : kenticoSector?.value[i].codename;
            if (!urlSector && codeName === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME) return true;
            if (urlSector && (codeName === CommonConstants.KONTENT_TAXONOMY_ALL_CODENAME || codeName === urlSector)) {
                return true;
            }
        }
        return false;
    });
};

const filterByUrlHsCode = (filteredData: ContentItem[], urlHsCode: string) => {
    return filteredData.filter((item) => {
        const kenticoHsCode = item.elements.hscodesnippet__hscode;

        if (!kenticoHsCode || !urlHsCode) return true;

        for (let i = 0; i < kenticoHsCode?.value.length; i++) {
            let value = kenticoHsCode?.value[i];
            if (urlHsCode && (value === CommonConstants.KONTENT_HSCODES_ALL_CODENAME || value === `n${urlHsCode}`)) {
                return true;
            }
        }

        return false;
    });
};

const filterByUrlMarket = (filteredData: ContentItem[], urlMarket: string) => {
    return filteredData.filter((item) => {
        const kenticoMarket = item.elements.marketcodesnippet__market;

        if (!kenticoMarket || !urlMarket) return true;

        for (let i = 0; i < kenticoMarket?.value.length; i++) {
            let codeName = kenticoMarket?.value[i].codename;
            if (urlMarket && (codeName === CommonConstants.KONTENT_TAXONOMY_ALL_CODENAME || codeName === urlMarket)) {
                return true;
            }
        }
        return false;
    });
};

export const filterContentItemAgainstUrlParams = (kenticoItem: ContentItem, urlParams: any) => {
    const filteredItem = filterContentItemsAgainstUrlParams([kenticoItem], urlParams);
    return filteredItem?.length > 0 ? filteredItem[0] : null;
};

export const filterContentItemsAgainstUrlParams = (kenticoData: ContentItem[], urlParams: any) => {
    const { sectorCode: urlSector, marketCode: urlMarket, hsCode: urlHsCode } = urlParams || {};
    let filteredItems = kenticoData;

    filteredItems = urlHsCode ? filteredItems : filterByUrlSector(filteredItems, urlSector);
    filteredItems = urlSector ? filteredItems : filterByUrlHsCode(filteredItems, urlHsCode);
    filteredItems = filterByUrlMarket(filteredItems, urlMarket);

    return filteredItems;
};

export const formatDate = (date: Date) => {
    return dayjs(date).format('D MMMM YYYY');
};

export const formatDateShowDay = (date: Date) => {
    return dayjs(date).format('dddd D MMMM YYYY');
};
