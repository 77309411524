import React, { useEffect, useRef, useContext, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { CommonConstants } from '../../CommonConstants';
import './ExportRulesFinderSearchContainer.scss';
import '../../controls/search/SearchFilterGroup.scss';
import RadioButtonGroup, { RadioButtonGroupItem } from '../../controls/radioButtonGroup/RadioButtonGroup';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import { ComButton, ContentItem } from '@exporter-services/common-ui';
import CommoditiesSearch, { Commodity } from '../../controls/search/CommoditiesSearch';
import { CommoditySearchItem } from '../../controls/search/CommoditySearchItem';
import NonAgriMarketsSearch from '../../controls/search/NonAgriMarketsSearch';
import MarketsByHsCodeSearch from '../../controls/search/MarketsByHsCodeSearch';
import NotListed from '../../controls/notListed/NotListed';
import TagManager from 'react-gtm-module';
import { useAppSelector, useAppDispatch } from '../../hooks/reduxHooks';
import { PageService } from '../../services/PageService';
import { MarketOption } from '../../models/MarketOption';
import { scrollTo } from '../../utils/scrollUtils';
import { useGetDisplayType } from '../../hooks/useGetDisplayType';
import { TooltipText } from '../../controls/tooltip/TooltipText';
import { personalisationContext } from '../../providers/personalisation';
import { PageName } from '../../models/PageName';
import { setHsCode, setIndustrySectorHsCode, clearSelection } from '../../providers/personalisation/reducer';
import { setIndustrySectorAndHsCode, clearProductsServicesSelection } from '../../providers/reducers/productServiceSelectionSlice';

interface ExportRulesFinderSearchContainerProps {
    data: {
        item?: ContentItem;
        linkedItems?: IContentItemsContainer;
    };
    onLoad?: (dataAvailable: boolean, title: string) => void;
}

interface IndustryOption {
    title: string;
    codeName: string;
    sectors: SectorOption[];
}

interface SectorOption {
    title: string;
    codeName: string;
}

const ExportRulesFinderSearchContainer = (props: ExportRulesFinderSearchContainerProps) => {
    const DL_EVENT_NAME = 'laws_and_regs_search_tool_search_button_click';
    const [clearCommoditiesSelection, setClearCommoditiesSelection] = useState<boolean>(false);
    const [selectedIndustry, setSelectedIndustry] = useState<IndustryOption>(null);
    const [selectedSector, setSelectedSector] = useState<SectorOption>(null);
    const [selectedMarket, setSelectedMarket] = useState<MarketOption>(null);

    const [showNotListedProductInformation, setShowNotListedProductInformation] = useState<boolean>(false);
    const [showNotListedMarket, setShowNotListedMarket] = useState<Boolean>(false);
    const [showNotListedIndustry, setShowNotListedIndustry] = useState<boolean>(false);
    const [showNotSureMaket, setshowNotSureMaket] = useState<Boolean>(false);
    const [showIndustriesList, setShowIndustriesList] = useState<boolean>(false);
    const [showSectorsList, setShowSectorsList] = useState<boolean>(false);
    const [strongMarketEntity, setStrongMarketEntity] = useState(null);

    const [showApplyFilterButton, setShowApplyFilterButton] = useState<boolean>(false);
    const [showNonAgriMarketSearch, setShowNonAgriMarketSearch] = useState<boolean>(false);
    const [showCommoditiesSearch, setShowCommoditiesSearch] = useState<boolean>(false);

    const [industries, setIndustries] = useState<IndustryOption[]>([]);
    const [selectedHsCode, setSelectedHsCode] = useState('');
    const FOOD_AND_AGRIBUSINESS_CODENAME = 'food_and_agribusiness';
    const wrapperRef = useRef(null);
    const footerRef = useRef(null);
    const industrySelectorRef = useRef(null);
    const sectorSelectorRef = useRef(null);
    const commoditySelectorRef = useRef(null);
    const nonAgriMarketSelectorRef = useRef(null);
    const productsMarketSelectorRef = useRef(null);
    const [hsCodesHelpText, setHsCodesHelpText] = useState<[string, string]>(null);
    useAppSelector<PageService>((state) => state.singletonServices.pageService);
    const { isTablet, isMobile } = useGetDisplayType();
    const { personalisationState, dispatch } = useContext(personalisationContext);

    const productSearchFilterRadioButtonGroupItem: RadioButtonGroupItem = {
        Id: CommonConstants.PRODUCTS_RADIOBUTTON_VALUE,
        Title: CommonConstants.PRODUCTS_RADIOBUTTON_TITLE,
        Value: CommonConstants.PRODUCTS_RADIOBUTTON_VALUE,
    };
    const serviceSelectionFilterRadioButtonGroupItem: RadioButtonGroupItem = {
        Id: CommonConstants.SERVICES_RADIOBUTTON_VALUE,
        Title: CommonConstants.SERVICES_RADIOBUTTON_TITLE,
        Value: CommonConstants.SERVICES_RADIOBUTTON_VALUE,
    };

    const [searchFilterRadioButtonGroupItemSelection, setSearchFilterRadioButtonGroupItemSelection] = useState<RadioButtonGroupItem>(
        productSearchFilterRadioButtonGroupItem,
    );

    const reduxDispatch = useAppDispatch();

    useEffect(() => {
        (async () => {
            if (window.location.href.indexOf('?') > -1) {
                await urlParamsOverride();
            }
        })();
    }, [window.location.search]);

    useEffect(() => {
        let extractedIndustries = extractIndustries();
        let previousHsCodeSelection = personalisationState.hsCode;
        extractHSCodesHelpText();
        setSelectedHsCode(previousHsCodeSelection);

        if (previousHsCodeSelection && searchFilterRadioButtonGroupItemSelection.Value === CommonConstants.PRODUCTS_RADIOBUTTON_VALUE) {
            setupPageInProductSearchMode(previousHsCodeSelection);
        } else if (personalisationState.industryCode) {
            let previousIndustrySelection = extractedIndustries.find((i) => i.codeName === personalisationState.industryCode);

            let previousSectorSelection = previousIndustrySelection?.sectors?.find((s) => s.codeName === personalisationState.sectorCode);

            if (!previousSectorSelection && personalisationState.industryCode === personalisationState.sectorCode) {
                previousSectorSelection = { codeName: personalisationState.sectorCode, title: null }; //It might be an industry taxonomy with no sub sector like defence
            }

            if (!previousIndustrySelection || !previousSectorSelection) {
                setupPageInProductSearchMode('');
            } else {
                setSelectedIndustry(previousIndustrySelection);
            }

            if (previousIndustrySelection && previousSectorSelection) {
                setupPageInServiceSelectionMode(previousIndustrySelection, previousSectorSelection.codeName);
            } else {
                setupPageInProductSearchMode('');
            }
        } else {
            setSelectedHsCode('');
            setupPageInProductSearchMode('');
        }

        setIndustries(extractedIndustries);
    }, [personalisationState.industryCode, personalisationState.sectorCode, personalisationState.hsCode]);

    const extractIndustries = () => {
        let industries: IndustryOption[] = [];
        let industryNotListedFound = false;

        props.data.item?.elements.list_of_questions['linkedItems'][1].elements.dropdown_item['linkedItems'].forEach((i) => {
            let sectors: SectorOption[] = [];

            i.elements.filtered_component['linkedItems']
                .filter((fc) => fc?.elements.industry_sector?.value?.length > 0)
                .forEach((fc) => {
                    let sector: SectorOption = {
                        title: fc?.elements.title?.value,
                        codeName: fc?.elements.industry_sector?.value[0]?.codename,
                    };

                    sectors.push(sector);
                });

            let industry: IndustryOption = {
                title: i.elements.title?.value,
                codeName: i.elements.industry_sector?.value[0]?.codename,
                sectors: [...sectors],
            };

            industryNotListedFound = industry.codeName === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME;
            industries.push(industry);
        });

        if (!industryNotListedFound) {
            industries.push({
                title: CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_LABEL,
                codeName: CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME,
                sectors: [],
            });
        }

        return industries;
    };

    const urlParamsOverride = async () => {
        const params = new URLSearchParams(window.location.search);
        const industryParam = params.get('industry');
        const sectorParam = params.get('sector');
        const hsCodeParam = params.get('hscode');

        if (industryParam && sectorParam) {
            const industryParamValue = replaceAll(industryParam, '-', '_');
            const sectorParamValue = replaceAll(sectorParam, '-', '_');

            if (sectorParamValue === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME) {
                dispatch(clearSelection());
            } else {
                reduxDispatch(setIndustrySectorAndHsCode(industryParamValue, sectorParamValue, ''));
                dispatch(setIndustrySectorHsCode(industryParamValue, sectorParamValue, ''));
            }
        } else if (hsCodeParam) {
            reduxDispatch(setIndustrySectorAndHsCode('', '', hsCodeParam));
            dispatch(setHsCode(hsCodeParam));
        }
    };

    function replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    function searchFilterRadioButtonItemSelected(item: RadioButtonGroupItem) {
        setSearchFilterRadioButtonGroupItemSelection(item);

        if (item.Value === CommonConstants.PRODUCTS_RADIOBUTTON_VALUE) {
            extractStrongMarketEntityForProductSearch();
            setupPageInProductSearchMode(selectedHsCode);
        } else {
            setupPageInServiceSelectionMode(selectedIndustry, selectedSector?.codeName);
        }
    }

    const setupPageInProductSearchMode = (hsCode: string) => {
        setShowIndustriesList(false);
        setShowSectorsList(false);
        setShowCommoditiesSearch(true);
        setShowApplyFilterButton(false);
        setSelectedIndustry(null);
        setSelectedSector(null);
        setSelectedMarket(null);
        hideAllWarnings();
        if (hsCode === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME) {
            setShowNotListedProductInformation(true);
        }
        setSearchFilterRadioButtonGroupItemSelection(productSearchFilterRadioButtonGroupItem);
        reduxDispatch(setIndustrySectorAndHsCode('', '', hsCode));
    };

    const setupPageInServiceSelectionMode = (industry: IndustryOption, sector: string) => {
        setSelectedHsCode(null);
        setShowCommoditiesSearch(false);
        setShowIndustriesList(true);
        setSelectedMarket(null);
        setShowApplyFilterButton(false);
        hideAllWarnings();
        if (industry?.sectors?.length > 0) {
            let previousSectorSelection = industry?.sectors?.find((s) => s.codeName === sector);
            setSelectedSector(previousSectorSelection);
            setShowSectorsList(true);
            setShowNonAgriMarketSearch(true);
            reduxDispatch(setIndustrySectorAndHsCode(industry?.codeName, previousSectorSelection?.codeName, ''));
        } else if (industry) {
            setSelectedSector({ codeName: industry?.codeName, title: null });
            setShowSectorsList(false);
            if (industry.codeName === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME) {
                setShowNotListedIndustry(true);
            } else {
                setShowNonAgriMarketSearch(true);
            }
            reduxDispatch(setIndustrySectorAndHsCode(industry?.codeName, industry?.codeName, ''));
        } else {
            reduxDispatch(setIndustrySectorAndHsCode('', '', ''));
        }

        setSearchFilterRadioButtonGroupItemSelection(serviceSelectionFilterRadioButtonGroupItem);
    };

    const handleSearchOnClick = () => {
        if (!showApplyFilterButton) {
            return;
        }

        if (searchFilterRadioButtonGroupItemSelection.Value === productSearchFilterRadioButtonGroupItem.Value) {
            setSelectedIndustry(null);
            setSelectedSector(null);
            dispatch(setHsCode(selectedHsCode));
            reduxDispatch(setIndustrySectorAndHsCode('', '', selectedHsCode));
        } else {
            setSelectedHsCode(null);
            dispatch(setIndustrySectorHsCode(selectedIndustry?.codeName, selectedSector?.codeName, ''));
            reduxDispatch(setIndustrySectorAndHsCode(selectedIndustry?.codeName, selectedSector?.codeName, ''));
        }

        TagManager.dataLayer({
            dataLayer: {
                event: DL_EVENT_NAME,
                dlv_industry: showNonAgriMarketSearch ? selectedIndustry.codeName.toLowerCase() : '',
                dlv_sector: showNonAgriMarketSearch ? selectedSector.codeName.toLowerCase() : '',
                dlv_product_code: !showNonAgriMarketSearch ? selectedHsCode : '',
                dlv_service_or_product: showNonAgriMarketSearch ? 'service' : 'product',
                dlv_market: selectedMarket ? selectedMarket.Code : undefined,
            },
        });

        window.location.href = getSearchButtonHref();
    };

    const isIndustrySelected = (industry) => {
        return selectedIndustry && industry.codeName === selectedIndustry.codeName;
    };

    const onIndustryFocus = () => {
        if (isMobile || isTablet) {
            scrollTo(industrySelectorRef);
        }
    };

    const changeIndustry = (event) => {
        let codeName = event.target.value;
        if (!codeName) return;
        let industry = industries.find((i) => i.codeName === codeName);
        setSelectedIndustry(industry);
        setSelectedSector(null);
        setSelectedHsCode('');
        setSelectedMarket(null);
        setShowApplyFilterButton(false);
        extractStrongMarketEntity(industry);

        const isNotListed = industry.codeName === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME;
        if (industry.sectors.length > 0) {
            setShowSectorsList(true);
        } else {
            setShowSectorsList(false);
            let selectedSector: SectorOption = { title: industry.title, codeName: industry.codeName };
            setSelectedSector(selectedSector);
            let isFoodAndAgri = industry.codeName === FOOD_AND_AGRIBUSINESS_CODENAME;
            setShowNonAgriMarketSearch(!isFoodAndAgri && !isNotListed);
        }

        if (isNotListed) {
            setShowApplyFilterButton(false);
            setShowNotListedMarket(false);
            setshowNotSureMaket(false);
            setShowNotListedIndustry(true);
        } else {
            hideAllWarnings();
        }
    };

    const onSectorFocus = () => {
        if (isMobile || isTablet) {
            scrollTo(sectorSelectorRef);
        }
    };

    const changeSector = (event) => {
        let codeName = event.target.value;
        if (!codeName) return;
        let sector = selectedIndustry.sectors.find((s) => s.codeName === codeName);
        setSelectedSector(sector);
        setSelectedMarket(null);
        setShowNonAgriMarketSearch(true);
    };

    const isSectorSelected = (sector) => {
        return selectedSector && sector.codeName === selectedSector.codeName;
    };

    const extractStrongMarketEntity = (theIndustry: IndustryOption) => {
        if (theIndustry?.codeName !== FOOD_AND_AGRIBUSINESS_CODENAME) return setStrongMarketEntity(null);
        setStrongMarketEntity(
            props.data.item?.elements.list_of_questions['linkedItems'][1].elements.dropdown_item['linkedItems'].filter(
                (v) => v.elements.industry_sector?.value[0]?.codename === theIndustry.codeName,
            )[0],
        );
    };

    const extractStrongMarketEntityForProductSearch = () => {
        setStrongMarketEntity(
            props.data.item?.elements.list_of_questions['linkedItems'][2]?.elements.dropdown_item['linkedItems'].filter(
                (v) => v.elements.industry_sector?.value[0]?.codename === CommonConstants.KONTENT_TAXONOMY_ALL_CODENAME,
            )[0],
        );
    };

    const extractHSCodesHelpText = () => {
        let hsCodesHelpTextResult = props.data.item?.elements.list_of_questions['linkedItems'].find((item) => {
            if (item?.system?.codename === CommonConstants.KONTENT_HSCODES_TOOLTIP_CODENAME) {
                return item;
            }
            return null;
        });

        if (hsCodesHelpTextResult) {
            let header = hsCodesHelpTextResult.elements.header?.value;
            let description = hsCodesHelpTextResult.elements.description?.value;
            if (header && description) {
                setHsCodesHelpText([header, description]);
            }
        }
    };

    const onCommodityFocus = () => {
        if (isMobile || isTablet) {
            scrollTo(commoditySelectorRef);
        }
    };

    const onCommodityClick = (selectedOption: Commodity) => {
        setSelectedHsCode(selectedOption?.Code);
        setShowNonAgriMarketSearch(false);
        setClearCommoditiesSelection(false);
        let isListed = selectedOption?.Code !== CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME && selectedOption?.Code !== null;
        if (!isListed) {
            setSelectedHsCode('');
            setShowApplyFilterButton(false);
        }
        hideAllWarnings();
        setShowNotListedProductInformation(selectedOption && !isListed);
    };

    const onNonAgriMarketsHover = () => {
        if (isMobile || isTablet) {
            scrollTo(nonAgriMarketSelectorRef);
        }
    };

    const onNonAgriMarketsSelection = (selectedOption: MarketOption) => {
        if (!selectedOption) return;
        setShowNotListedMarket(false);
        setshowNotSureMaket(false);
        setShowNotListedIndustry(false);

        if (
            selectedOption.Code === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME ||
            selectedOption.Code === CommonConstants.KONTENT_TAXONOMY_NOT_SURE_CODENAME
        ) {
            setShowApplyFilterButton(false);
            setSelectedMarket(null);
            if (selectedOption.Code === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME) {
                setshowNotSureMaket(false);
                setShowNotListedMarket(true);
            }
            if (selectedOption.Code === CommonConstants.KONTENT_TAXONOMY_NOT_SURE_CODENAME) {
                setShowNotListedMarket(false);
                setshowNotSureMaket(true);
            }
            return;
        }

        selectedOption && setShowNotListedIndustry(false);
        setShowApplyFilterButton(!!selectedOption);
        setShowNonAgriMarketSearch(true);
        setSelectedMarket(selectedOption ?? null);
    };

    const onMarketFocus = () => {
        if (isMobile || isTablet) {
            scrollTo(productsMarketSelectorRef);
        }
    };

    const onMarketsSelection = (selectedOption: MarketOption) => {
        if (!selectedOption) return;
        setShowNotListedMarket(false);
        setshowNotSureMaket(false);

        if (
            selectedOption.Code === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME ||
            selectedOption.Code === CommonConstants.KONTENT_TAXONOMY_NOT_SURE_CODENAME
        ) {
            setShowApplyFilterButton(false);
            setSelectedMarket(null);
            if (selectedOption.Code === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME) {
                setshowNotSureMaket(false);
                setShowNotListedMarket(true);
            }
            if (selectedOption.Code === CommonConstants.KONTENT_TAXONOMY_NOT_SURE_CODENAME) {
                setShowNotListedMarket(false);
                setshowNotSureMaket(true);
            }
            return;
        }

        setShowApplyFilterButton(!!selectedOption);
        setSelectedMarket(selectedOption ?? null);
    };

    const hideAllWarnings = (): void => {
        setShowNotListedMarket(false);
        setshowNotSureMaket(false);
        setShowNotListedProductInformation(false);
        setShowNotListedIndustry(false);
    };

    const getNotListedIndustry = () => {
        return props.data.linkedItems[props.data.linkedItems.export_rules_industry_search.elements.not_listed['linkedItemCodenames'][0]];
    };

    const getNotListedProduct = () => {
        return props.data.linkedItems[props.data.linkedItems.export_rules_product_search.elements.not_listed['linkedItemCodenames'][0]];
    };

    const getMarketNotListed = () => {
        const allProductslink = props.data.linkedItems[props.data.linkedItems.export_rules_product_search.elements.dropdown_item.value[0]];
        const lawsAndRegsSearch = props.data.linkedItems[allProductslink.elements.filtered_component.value[0]];
        return props.data.linkedItems[lawsAndRegsSearch.elements.market_not_listed['linkedItemCodenames'][0]];
    };

    const getMarketNotSure = () => {
        const allProductslink = props.data.linkedItems[props.data.linkedItems.export_rules_product_search.elements.dropdown_item.value[0]];
        const lawsAndRegsSearch = props.data.linkedItems[allProductslink.elements.filtered_component.value[0]];
        return props.data.linkedItems[lawsAndRegsSearch.elements.market_not_sure['linkedItemCodenames'][0]];
    };

    const getSearchButtonHref = () => {
        const commodityMarketUrlFragment = `${
            showNonAgriMarketSearch
                ? selectedIndustry?.codeName && selectedSector?.codeName
                    ? `${selectedIndustry.codeName.toLowerCase()}/${selectedSector.codeName.toLowerCase()}`
                    : ''
                : selectedHsCode?.toLowerCase() || ''
        }/${selectedMarket?.Code?.toLowerCase() || ''}`;
        return `/${PageName.LawsAndRegulations}/${PageName.ExportRulesFinder}/${showNonAgriMarketSearch ? PageName.ServiceRequirements : PageName.ProductRequirements}/${commodityMarketUrlFragment}`;
    };

    const clearFilter = () => {
        setSelectedIndustry(null);
        setSelectedSector(null);
        setSelectedHsCode(null);
        setClearCommoditiesSelection(true);
        setShowNotListedMarket(false);
        setshowNotSureMaket(false);
        setShowNotListedIndustry(false);
        setShowNotListedProductInformation(false);
        setShowApplyFilterButton(false);
        dispatch(clearSelection());
        reduxDispatch(clearProductsServicesSelection());

        if (window.location.search) {
            window.location.href = window.location.pathname;
            return;
        }

        if (isMobile || isTablet) {
            scrollTo(wrapperRef);
        }
    };

    return (
        <div ref={wrapperRef} className="exportrulesfindersearchcontainer-box-container">
            <Row className="exportrulesfindersearchcontainer-box">
                <Col md="12">
                    <RadioButtonGroup
                        items={[productSearchFilterRadioButtonGroupItem, serviceSelectionFilterRadioButtonGroupItem]}
                        legendAsLabel={true}
                        selectedValue={searchFilterRadioButtonGroupItemSelection.Value}
                        name={'looking_export'}
                        label={CommonConstants.EXPORT_TYPE_SELECTION_LABEL}
                        onChangeHandler={(e, itm) => searchFilterRadioButtonItemSelected(itm)}
                    />
                    {showIndustriesList && (
                        <>
                            <div ref={industrySelectorRef} className="filtergroup-form-field-container">
                                <label className="field-label" htmlFor="industry">
                                    Select industry
                                </label>
                                <select
                                    className="form-field form-select-dropdown"
                                    id="industry"
                                    name="industry"
                                    onChange={changeIndustry}
                                    onFocus={onIndustryFocus}
                                >
                                    {!selectedIndustry && (
                                        <option key="select_an_industry" value="">
                                            Select an industry
                                        </option>
                                    )}
                                    {industries.map((industry) => (
                                        <option
                                            className={`${isIndustrySelected(industry) ? 'selected' : ''} filter-item-select-option`}
                                            key={industry.codeName}
                                            value={industry.codeName}
                                            selected={isIndustrySelected(industry)}
                                        >
                                            {industry.title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </>
                    )}
                    {selectedIndustry && showSectorsList && (
                        <>
                            <div ref={sectorSelectorRef} className="filtergroup-form-field-container">
                                <label className="field-label" htmlFor="sector">
                                    Select sector
                                </label>
                                <select
                                    className="form-field form-select-dropdown"
                                    id="sector"
                                    name="sector"
                                    onChange={changeSector}
                                    onFocus={onSectorFocus}
                                >
                                    {!selectedSector && (
                                        <option key="select_a_sector" value="">
                                            Select a sector
                                        </option>
                                    )}
                                    {selectedIndustry.sectors.map((sector) => (
                                        <option
                                            className={`${isSectorSelected(sector) ? 'selected' : ''}`}
                                            key={sector.codeName}
                                            value={sector.codeName}
                                            selected={isSectorSelected(sector)}
                                        >
                                            {sector.title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </>
                    )}
                    {showCommoditiesSearch && (
                        <div ref={commoditySelectorRef} className="filtergroup-form-field-container">
                            <label className="field-label" htmlFor="commodities-search">
                                {strongMarketEntity?.elements.information_for?.value}
                            </label>
                            <CommoditiesSearch
                                item={strongMarketEntity}
                                searchItem={strongMarketEntity?.elements.filtered_component?.linkedItems[0] as CommoditySearchItem}
                                onClick={onCommodityClick}
                                onFocus={onCommodityFocus}
                                PnPFilter={false}
                                industryCode={CommonConstants.PRODUCTS_CODENAME}
                                commodityCode={selectedHsCode}
                                isExportRules={true}
                                ariaLabel={strongMarketEntity?.elements.information_for?.value}
                                clearSelection={clearCommoditiesSelection}
                            />
                            {hsCodesHelpText && <TooltipText data={{ header: hsCodesHelpText[0], description: hsCodesHelpText[1] }} />}
                        </div>
                    )}
                    {selectedIndustry && selectedSector && showNonAgriMarketSearch && (
                        <div ref={nonAgriMarketSelectorRef} className="filtergroup-form-field-container">
                            <label className="field-label" htmlFor="servicesMarketSearch">
                                {props.data.item?.elements.list_of_questions['linkedItems'][1].elements.nonagri_market_label?.value}
                            </label>
                            <NonAgriMarketsSearch
                                item={props.data.item?.elements.list_of_questions['linkedItems'][1]}
                                sector={selectedSector.codeName}
                                onClick={onNonAgriMarketsSelection}
                                onFocus={onNonAgriMarketsHover}
                                ariaLabel={props.data.item?.elements.list_of_questions['linkedItems'][1].elements.nonagri_market_label?.value}
                            />
                        </div>
                    )}
                    {selectedHsCode && !showNotListedProductInformation && (
                        <div ref={productsMarketSelectorRef} className="filtergroup-form-field-container">
                            <label className="field-label" htmlFor="productsMarketSearch">
                                {props.data.item?.elements.list_of_questions['linkedItems'][2].elements.nonagri_market_label?.value}
                            </label>
                            <MarketsByHsCodeSearch
                                onClick={onMarketsSelection}
                                onFocus={onMarketFocus}
                                hsCode={selectedHsCode}
                                ariaLabel={props.data.item?.elements.list_of_questions['linkedItems'][2].elements.nonagri_market_label?.value}
                            />
                        </div>
                    )}
                </Col>
            </Row>
            <Row>
                {showNotListedIndustry && (
                    <Col>
                        <NotListed
                            data={{ item: getNotListedIndustry(), linkedItems: props.data.linkedItems }}
                            onLoad={props.onLoad}
                            type={'industry_not_listed'}
                        />
                    </Col>
                )}
                {showNotListedProductInformation && (
                    <Col>
                        <NotListed
                            data={{ item: getNotListedProduct(), linkedItems: props.data.linkedItems }}
                            onLoad={props.onLoad}
                            type={'search_dropdown_question'}
                        />
                    </Col>
                )}
                {showNotListedMarket && (
                    <Col>
                        <NotListed
                            data={{ item: getMarketNotListed(), linkedItems: props.data.linkedItems }}
                            onLoad={props.onLoad}
                            type={'market_not_listed'}
                        />
                    </Col>
                )}
                {showNotSureMaket && (
                    <Col>
                        <NotListed
                            data={{ item: getMarketNotSure(), linkedItems: props.data.linkedItems }}
                            onLoad={props.onLoad}
                            type={'market_not_sure'}
                        />
                    </Col>
                )}
            </Row>
            <Row>
                <Col>
                    <div ref={footerRef} className="filtergroup-filter-footer button-container">
                        <ComButton
                            id="clearFilters"
                            variant="link"
                            className="filtergroup-clear-filters refresh-icon"
                            role="button"
                            aria-pressed="false"
                            onClick={clearFilter}
                        >
                            <span>Start again</span>
                        </ComButton>

                        {showApplyFilterButton && (
                            <ComButton
                                id="personalization_filter_selection"
                                className="sticktoright"
                                onClick={handleSearchOnClick}
                                data-gtm-market={selectedMarket?.Code}
                                data-gtm-clickurl={selectedMarket && getSearchButtonHref()}
                            >
                                See results
                            </ComButton>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    );
};
export default ExportRulesFinderSearchContainer;
