import React, { useContext } from 'react';
import './ProsAndCons.scss';
import { personalisationContext } from '../../providers/personalisation';
import { validatePersonalisation } from '../../utils/personalisationUtils';
import { ContentItem } from '@exporter-services/common-ui';
import ResolveHeadingLevel from '../resolveHeadingLevel/ResolveHeadingLevel';

interface ProsAndConsProps {
    data: {
        item: ContentItem;
    };
}

const ProsAndCons = (props: ProsAndConsProps) => {
    const { personalisationState } = useContext(personalisationContext);

    return (
        <>
            {!validatePersonalisation(personalisationState, props.data.item?.elements.industry_sector) ? (
                <></>
            ) : (
                <div className="pros-and-cons">
                    <div className="pros me-md-6">
                        <div className="pro-icon-container">
                            <div className="pro-icon">
                                <span className="pro-svg-wrapper thumbs-up-icon"></span>
                            </div>
                        </div>
                        <ResolveHeadingLevel
                            data={{
                                headingLevel: props.data.item.elements.headinglevelsnippet__level,
                                titleText: !!props.data.item.elements?.pros_list_title?.value
                                    ? props.data.item.elements.pros_list_title.value
                                    : 'Pros',
                            }}
                        />
                        <div className="no-space-under-last-child" dangerouslySetInnerHTML={{ __html: props.data.item.elements.pros.value }} />
                    </div>
                    <div className="cons">
                        <div className="cons-icon-container">
                            <div className="con-icon">
                                <span className="con-svg-wrapper thumbs-down-icon"></span>
                            </div>
                        </div>
                        <ResolveHeadingLevel
                            data={{
                                headingLevel: props.data.item.elements.headinglevelsnippet__level,
                                titleText: !!props.data.item.elements?.cons_list_title?.value
                                    ? props.data.item.elements.cons_list_title.value
                                    : 'Cons',
                            }}
                        />
                        <div className="no-space-under-last-child" dangerouslySetInnerHTML={{ __html: props.data.item.elements.cons.value }} />
                    </div>
                </div>
            )}
        </>
    );
};

export default ProsAndCons;
