import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { MarketOption } from '../../models/MarketOption';
import { ReactSelectOption } from '../../models/ReactSelectOption';
import parse from 'html-react-parser';
import { default as Select } from 'react-select';
import { CommonConstants } from '../../CommonConstants';
import './MarketSearchBase.scss';

export interface MarketsSearchBaseProps {
    id: string;
    ariaLabel: string;
    marketOptions: MarketOption[];
    setMarketSearchInputRef: (ref: any) => void;
    handleOnChange: (selectedOption: MarketOption) => void;
    handleInputChange?: (searchText: string) => void;
    selectedValue: MarketOption;
    class: string;
    onFocus?: Function;
}

const MarketsSearchBase = (props: MarketsSearchBaseProps) => {
    const [searchText, setSearchText] = useState('');

    const convertToMarketOption = (option: ReactSelectOption): MarketOption => {
        if (option) {
            return { Code: option.value, Label: option.label };
        }
        return null;
    };

    const convertToReactSelectOption = (option: MarketOption): ReactSelectOption => {
        if (option) {
            return { value: option.Code, label: option.Label };
        }
        return null;
    };

    const filterOptions = (candidate, input) => {
        if (input) {
            return (
                [CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME, CommonConstants.KONTENT_TAXONOMY_NOT_SURE_CODENAME].includes(
                    candidate?.data?.value,
                ) || candidate?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        }
        return true;
    };

    const handleOnChange = (selectedOption: ReactSelectOption) => {
        setSearchText('');
        props.handleOnChange(convertToMarketOption(selectedOption));
    };

    const handleOnInputChange = (searchtext: string) => {
        setSearchText(searchtext);
        if (searchText && props.handleInputChange) {
            props.handleInputChange(searchtext);
        }
    };

    const formOptionHandler = (e) => {
        const optionText = searchText ? e.label.replace(new RegExp(`(${searchText})`, 'i'), '<strong class="highlight">$1</strong>') : parse(e.label);

        return <div className="w-100" dangerouslySetInnerHTML={{ __html: optionText as string }} />;
    };

    const onDropdownFocus = () => {
        props.onFocus?.();
    };

    return (
        <div className={`${props.class ? ` ${props.class}` : ''}`}>
            <Row>
                <Col md="12">
                    {props.marketOptions && (
                        <Select
                            ref={props.setMarketSearchInputRef}
                            inputId={props.id}
                            key={`my_unique_select_key__${JSON.stringify(props.selectedValue)}`}
                            placeholder=""
                            getOptionValue={(e) => e.label}
                            onChange={handleOnChange}
                            onInputChange={handleOnInputChange}
                            options={props.marketOptions.map((m) => convertToReactSelectOption(m))}
                            filterOption={filterOptions}
                            isClearable
                            isSearchable
                            styles={{
                                singleValue: () => ({}),
                            }}
                            classNamePrefix="market-search"
                            value={convertToReactSelectOption(props.selectedValue) || ''}
                            noOptionsMessage={() => null}
                            formatOptionLabel={formOptionHandler}
                            aria-label={props.ariaLabel}
                            onFocus={onDropdownFocus}
                        />
                    )}
                </Col>
            </Row>
        </div>
    );
};
export default MarketsSearchBase;
