import React, { ReactNode, useEffect, useRef, useState } from 'react';
import Footer from '../site/footer/Footer';
import Header from '../site/header/Header';
import UtilityGroup from '../controls/utilityGroup/UtilityGroup';
import { client } from '../config';
import { Responses } from '@kontent-ai/delivery-sdk';
import { MenuItemData } from './header/MenuItemData';

interface LayoutProps {
    children: ReactNode;
    onNavigationDataRetrieved?: (response: Responses.IListContentItemsResponse) => void;
}

const Layout = (props: LayoutProps) => {
    const [items, setItems] = useState<any[]>();

    useEffect(() => {
        getNavigationData();
    }, []);

    const getNavigationData = () => {
        client
            .items()
            .type('homepage')
            .depthParameter(3)
            .toPromise()
            .then((response) => {
                setItems(response.data.items[0].elements.subpages['linkedItems']);
                props.onNavigationDataRetrieved?.(response.data);
            });
    };

    const nextMainMenuId = useRef(0);

    const buildNavData = (items: any, level: number, parentMenuPath: string | null, parentMenuId: string | null) => {
        let menuItemsArray: MenuItemData[] = [];
        if (items) {
            let nextMenuItemId = 0;
            for (let menuItem of items) {
                if (
                    menuItem.elements.show_in_navigation &&
                    menuItem.elements.show_in_navigation.value.length > 0 &&
                    menuItem.elements.show_in_navigation.value[0].codename === 'yes'
                ) {
                    let parentPath = parentMenuPath ? parentMenuPath + '/' + menuItem.elements.url.value : '/' + menuItem.elements.url.value;
                    let id = level === 1 ? (nextMainMenuId.current++).toString() : parentMenuId + '_' + nextMenuItemId++;
                    const a = [
                        {
                            id: id,
                            title: menuItem.elements.title.value,
                            url: parentPath,
                            level: level,
                            menuItems: buildNavData(menuItem.elements.subpages['linkedItems'], level + 1, parentPath, id),
                        },
                    ];
                    menuItemsArray = [...menuItemsArray, ...a];
                }
            }
            return menuItemsArray;
        }
    };

    const data = buildNavData(items, 1, null, null);

    return (
        <>
            <Header data={{ menu: data }} />
            {props.children}
            <UtilityGroup />
            <Footer />
        </>
    );
};

export default Layout;
