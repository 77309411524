import React, { createRef, useEffect, useState } from 'react';
import './ComRadioButton.scss';
import TagManager from 'react-gtm-module';
import { isStringNullOrUndefinedOrEmpty } from '../../../utils/stringHelper';

export interface ComRadioButtonItem {
    Id: string;
    Title: string;
    Value: string;
    Index: number;
}

export interface ComRadioButtonProps {
    data: ComRadioButtonItem;
    refsCreated: boolean;
    selectedValue: string;
    focusedValue?: string;
    name: string;
    setLabelCssClass?: () => string;
    setLabelStyle?: (item: ComRadioButtonItem) => React.HTMLAttributes<HTMLLabelElement>;
    handleOnChange: (evt: React.MouseEvent<HTMLInputElement>, selectedValue: string) => void;
    handleOnFocusChange?: (evt: React.FocusEvent<HTMLInputElement>, focusedValue: string) => void;
    handleOnActiveChange?: (evt: React.MouseEvent<HTMLLabelElement>, activatedValue: string) => void;
    handleOnRefCreate: (ref: React.RefObject<HTMLInputElement>) => void;
}

const ComRadioButton = (props: ComRadioButtonProps) => {
    const [focusedValue, setFocusedValue] = useState<string>(null);
    const [activatedValue, setActivatedValue] = useState<string>(null);
    const ref = createRef<HTMLInputElement>();
    const DL_EVENT_NAME = 'radiobutton_click';

    useEffect(() => {
        props.handleOnRefCreate(ref);
    }, []);

    useEffect(() => {
        if (props.data.Value !== props.selectedValue) return;

        if (props.refsCreated) {
            if (props.selectedValue) {
                ref.current.click();
            }
        }
    }, [props.data.Value, props.refsCreated, props.selectedValue]);

    useEffect(() => {
        if (!isStringNullOrUndefinedOrEmpty(props.focusedValue) && props.focusedValue !== focusedValue && props.focusedValue === props.data.Value) {
            ref.current.focus();
        }
    }, [props.focusedValue]);

    const getId = (title: string): string =>
        title
            ?.replace(/\s+/g, '-')
            .replace(/[^\w-]/g, '')
            .toLowerCase();

    const handleLabelOnMouseUp = (evt: React.MouseEvent<HTMLLabelElement>) => {
        const dataValueAttr = (evt.target as HTMLLabelElement).attributes['data-value'];
        if (dataValueAttr) {
            setActivatedValue(null);
            setFocusedValue(null);
        }
    };

    const handleLabelOnMouseDown = (evt: React.MouseEvent<HTMLLabelElement>) => {
        const dataValueAttr = (evt.target as HTMLLabelElement).attributes['data-value'];
        if (dataValueAttr) {
            setActivatedValue(dataValueAttr.value);
            props.handleOnActiveChange?.(evt, dataValueAttr.value);
        }
    };

    const handleOnFocus = (evt: React.FocusEvent<HTMLInputElement>): void => {
        const value = (evt.target as HTMLInputElement).value;
        setFocusedValue(value);
        props.handleOnFocusChange?.(evt, value);
    };

    const handleOnBlur = (evt: React.FocusEvent<HTMLInputElement>): void => {
        setFocusedValue(null);
        props.handleOnFocusChange?.(evt, null);
    };

    const handleOnClick = (evt: React.MouseEvent<HTMLInputElement>): void => {
        evt.stopPropagation();

        props.handleOnChange(evt, props.data.Value);

        TagManager.dataLayer({
            dataLayer: {
                event: DL_EVENT_NAME,
                dlv_radiobutton_text: props.data.Value,
            },
        });
    };

    const key = getId(`radio-button-${props.name}-${props.data.Index}-${props.data.Value}`);

    return (
        <label
            style={props.setLabelStyle?.(props.data)}
            className={`radio-button ${props.setLabelCssClass ? props.setLabelCssClass() : ''}${
                props.data.Value === props.selectedValue ? ' selected' : ''
            }${props.data.Value === focusedValue ? ' focused' : ''}${props.data.Value === activatedValue ? ' activated' : ''}`}
            onMouseUp={handleLabelOnMouseUp}
            onMouseDown={handleLabelOnMouseDown}
            data-value={props.data.Value}
            key={`label-${key}`}
        >
            <input
                ref={ref}
                type="radio"
                id={props.data.Id}
                key={key}
                name={props.name}
                value={props.data.Value}
                checked={props.data.Value === props.selectedValue}
                onClick={handleOnClick}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                onChange={() => void 0} /** to fix warning during compilation, it doesn't affect
                functionality as we are subscribing to onClick event **/
            />
            {props.data.Title}
        </label>
    );
};

export default ComRadioButton;
