import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import BackArrow from '../../assets/backArrow.svg';

const FeedbackBanner = () => {
    return (
        <div data-testid="feedback">
            <Container fluid className="feedback-banner d-print-none">
                <Container>
                    <Row>
                        <Col xs={11} sm={4} md={6} className="businessgovau-holder ">
                            <ul className="p-0 m-0">
                                <li className="businessgovau-link p-0">
                                    <a aria-label="Australian Government logo. Business, business.gov.au" href="https://www.business.gov.au/">
                                        <img src={BackArrow} alt="Back arrow" />
                                        <strong>business</strong>.gov.au
                                    </a>
                                </li>
                            </ul>
                        </Col>
                        <Col xs={11} sm={8} md={6} className="feedback-holder text-sm-end">
                            <ul className="p-0 m-0">
                                <li className="feedback-link p-0">
                                    Your{' '}
                                    <a
                                        href="https://surveys.hotjar.com/de6d04cd-9c3c-4778-b98f-4c1be68a25e9"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="survey"
                                        data-testid="survey"
                                    >
                                        feedback<span className="sr-only">Opens in new window</span>
                                    </a>{' '}
                                    can improve this site.
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    );
};

export default FeedbackBanner;
