import React, { useState, useEffect } from 'react';
import { ContentItemElementsIndexer, IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import './AccordionItemGuidePathway.scss';
import { AccordionContentItemGuidePathway } from './AccordionContentItemGuidePathway';
import ChevronUpIcon from '../../assets/chevronUp333.svg';
import ChevronDownIcon from '../../assets/chevronDown333.svg';
import ResolveLink from '../resolveLink/ResolveLink';
import { filterContentItemAgainstUrlParams } from '../../utils/utils';
import DefaultImage from '../../assets/guidePathwayAccordionItemDefault.svg';
import ComponentResolver from '../../ComponentResolver';
import { ColorThemeEnum } from '@exporter-services/common-ui';
import TagManager from 'react-gtm-module';
import { GuidePathwayColorMapping, colorMappingsForAccordionItem } from './AccordionGuidePathwayColorMappings';
import { useUrlParams } from '../../hooks/useUrlParams';
import { CommonConstants } from '../../CommonConstants';

interface AccordionItemGuidePathwayProps {
    item: AccordionContentItemGuidePathway;
    expanded: boolean;
    linkedItems: IContentItemsContainer;
    match?: any;
}

const AccordionItemGuidePathway = (props: AccordionItemGuidePathwayProps) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [hover, setHover] = useState(false);
    const urlCodes = useUrlParams(props.match);

    const filteredItem = filterContentItemAgainstUrlParams(props.item, urlCodes);

    useEffect(() => {
        setExpanded(props.expanded);
    }, [props.expanded]);

    const handleOnKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            e.currentTarget.click();
        }
    };

    const handleOnClick = (titleText: string) => {
        const DL_EVENT_NAME = 'guide_accordion_click';
        if (!expanded) {
            TagManager.dataLayer({
                dataLayer: {
                    event: DL_EVENT_NAME,
                    dlv_industry: urlCodes?.industryCode || CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME,
                    dlv_sector: urlCodes?.sectorCode || CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME,
                    dlv_product_code: urlCodes?.hsCode || '',
                    dlv_service_or_product: 'service',
                    dlv_market: urlCodes?.marketCode || '',
                    dlv_guide_accordion_title: titleText,
                },
            });
        }

        setExpanded(!expanded);
    };

    const getColorMapping = (colorElement: ContentItemElementsIndexer): GuidePathwayColorMapping => {
        let colorThemeValue =
            colorElement?.value.length > 0 && ColorThemeEnum[colorElement.value[0].codename]
                ? ColorThemeEnum[colorElement.value[0].codename]
                : ColorThemeEnum.blue;
        let filterResult = colorMappingsForAccordionItem.filter((item) => item.colorName === colorThemeValue);

        return filterResult?.length > 0 ? filterResult[0] : colorMappingsForAccordionItem[0];
    };

    const getAccordionItemBackgroundColor = (colorElement: ContentItemElementsIndexer) => {
        let result =
            expanded || hover ? getColorMapping(colorElement)?.accordionItemHoverBackground : getColorMapping(colorElement)?.accordionItemBackground;
        return result;
    };

    const getChevronBackgroundColor = (colorElement: ContentItemElementsIndexer) => {
        let result = expanded || hover ? getColorMapping(colorElement)?.chevronHoverBackground : getColorMapping(colorElement)?.chevronBackground;
        return result;
    };

    const getAccordionItemExpandedBackgroundColor = (colorElement: ContentItemElementsIndexer) => {
        let result = getColorMapping(colorElement)?.accordionItemHoverBackground;
        return result;
    };

    const getImageBackgroundColor = (colorElement: ContentItemElementsIndexer) => {
        let result = expanded || hover ? getColorMapping(colorElement)?.imageHoverBackground : '#ffffff';
        return result;
    };

    const getTileBottomBorderWidth = () => {
        let result = expanded ? '0' : '1px';
        return result;
    };

    if (!filteredItem) return null;

    return (
        <div className="accordion-item-guide-pathway">
            <div
                className={`accordion-item-tile ${expanded ? 'open' : ''}`}
                style={{
                    borderLeftColor: getChevronBackgroundColor(filteredItem?.elements.colors),
                }}
                title={expanded ? 'Collapse' : 'Expand'}
                role={'button'}
                aria-expanded={expanded ? 'true' : 'false'}
                onClick={() => handleOnClick(filteredItem?.elements.title.value)}
                onKeyPress={handleOnKeyPress}
                tabIndex={0}
                onMouseEnter={() => {
                    setHover(true);
                }}
                onMouseLeave={() => {
                    setHover(false);
                }}
            >
                <div
                    className="accordion-item-tile-inner"
                    style={{
                        borderLeftColor: getChevronBackgroundColor(filteredItem?.elements.colors),
                        backgroundColor: getImageBackgroundColor(filteredItem?.elements.colors),
                        borderBottomWidth: getTileBottomBorderWidth(),
                    }}
                >
                    <div
                        className="guide-accordion-image"
                        style={{
                            backgroundColor: getImageBackgroundColor(filteredItem?.elements.colors),
                        }}
                    >
                        {filteredItem.elements.image && filteredItem.elements.image.value?.length > 0 ? (
                            <img
                                {...props}
                                src={filteredItem.elements.image.value[0].url}
                                alt={filteredItem.elements.image.value[0].description ?? ''}
                            />
                        ) : (
                            <img {...props} src={DefaultImage} alt={''} />
                        )}
                    </div>
                    <div
                        className="accordion-item-tile-inner-title p-4"
                        style={{
                            backgroundColor: getAccordionItemBackgroundColor(filteredItem?.elements.colors),
                        }}
                    >
                        <h3 className={expanded ? 'expanded' : 'closed'} data-testid="h3-title">
                            {' '}
                            {filteredItem?.elements.title.value}
                        </h3>
                        <p>
                            <ResolveLink data={{ item: filteredItem, match: props.match }} type={filteredItem.system.type} />
                        </p>
                    </div>

                    <div
                        className={`arrow ${expanded ? 'expanded' : 'closed'}`}
                        style={{
                            backgroundColor: getChevronBackgroundColor(filteredItem?.elements.colors),
                        }}
                        aria-label={filteredItem?.elements.title.value}
                        aria-expanded={expanded}
                        data-testid={'accordion-item-button'}
                        onMouseEnter={() => {
                            setHover(true);
                        }}
                        onMouseLeave={() => {
                            setHover(false);
                        }}
                    >
                        <span className="float-button-content">
                            <img src={!expanded ? ChevronDownIcon : ChevronUpIcon} alt=""></img>
                        </span>
                    </div>
                </div>
            </div>
            <div
                className={`accordion-item-content ${expanded ? 'visible' : ''}`}
                style={{
                    backgroundColor: getAccordionItemExpandedBackgroundColor(filteredItem?.elements.colors),
                    borderLeftColor: getChevronBackgroundColor(filteredItem?.elements.colors),
                }}
            >
                {filteredItem?.elements.linked_items['linkedItems']?.length > 0 &&
                    filteredItem?.elements.linked_items['linkedItems'].map((item) => (
                        <ComponentResolver data={{ item: item, linkedItems: props.linkedItems }} key={item.system.id} match={props.match} />
                    ))}
            </div>
        </div>
    );
};

export default AccordionItemGuidePathway;
