import React from 'react';
import './SearchDisclaimer.scss';
import { SearchDisclaimerItem } from './SearchDisclaimerItem';
import { Col, Row } from 'reactstrap';

interface SearchDisclaimerProps {
    data: {
        item: SearchDisclaimerItem;
    };
}

const SearchDisclaimer = (props: SearchDisclaimerProps) => {
    return (
        <div className="search-disclaimer">
            <Row className="search-disclaimer-container">
                <Col xs={12} sm={2} md={1} className="search-disclaimer-image">
                    <span className="disclaimer-icon"></span>
                </Col>
                <Col xs={12} sm={10} md={11}>
                    <div className="search-disclaimer-content">
                        <h3>{props.data.item.elements.title.value}</h3>
                        <div dangerouslySetInnerHTML={{ __html: props.data.item.elements.description.value }} />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default SearchDisclaimer;
