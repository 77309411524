import React from 'react';
import './Quote.scss';
import { filterContentItemAgainstUrlParams } from '../../utils/utils';
import BaseImage from '../baseImage/BaseImage';
import { ContentItem, ColorThemeEnum } from '@exporter-services/common-ui';
import { ContentItemElementsIndexer } from '@kontent-ai/delivery-sdk';
import { useUrlParams } from '../../hooks/useUrlParams';

interface QuoteProps {
    data: {
        item: ContentItem;
    };
    match?: any;
}

const Quote = (props: QuoteProps) => {
    const urlCodes = useUrlParams(props.match);
    const quoteItem = filterContentItemAgainstUrlParams(props.data.item, urlCodes);
    const hasQuoteImage = quoteItem?.elements.image?.value?.length > 0;

    const getQuoteThemeColour = (colorElement: ContentItemElementsIndexer) => {
        let colorThemeValue =
            colorElement?.value.length > 0 && Object.values(ColorThemeEnum).includes(colorElement.value[0].codename)
                ? colorElement.value[0].codename
                : ColorThemeEnum[ColorThemeEnum.teal];
        return colorThemeValue;
    };

    if (!quoteItem) return null;

    return (
        <div className={`quote-component pull-quote ${getQuoteThemeColour(quoteItem.elements.colors)}`}>
            <div className="quote-content">
                {hasQuoteImage && (
                    <div className="quote-image">
                        <BaseImage
                            src={quoteItem.elements.image.value[0].url}
                            alt={quoteItem.elements.image.value[0].description}
                            className="img-thumbnail"
                        ></BaseImage>
                    </div>
                )}
                <div className="quote-text">
                    <blockquote className="mb-3">{quoteItem.elements.quote.value}</blockquote>
                    <p className="author mb-0 pb-2">— {quoteItem.elements.name_and_date.value}</p>
                </div>
            </div>
        </div>
    );
};

export default Quote;
