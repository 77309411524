import React, { useContext } from 'react';
import './Download.scss';
import { DownloadItem } from './DownloadItem';
import { personalisationContext } from '../../providers/personalisation';
import { validatePersonalisation } from '../../utils/personalisationUtils';
import ResolveHeadingLevel from '../resolveHeadingLevel/ResolveHeadingLevel';

interface DownloadProps {
    data: {
        item: DownloadItem;
    };
}

const Download = (props: DownloadProps) => {
    const { personalisationState } = useContext(personalisationContext);
    const asset = props.data.item.elements.asset;

    const generateAriaLabel = () => {
        return `${asset.value[0].name} ${(asset.value[0].size / 1000).toFixed(2)} KB`;
    };

    return (
        <>
            {!validatePersonalisation(personalisationState, props.data.item?.elements.industry_sector) ? (
                <></>
            ) : (
                <div className="download-information" data-testid="download-information" aria-label={generateAriaLabel()}>
                    <ResolveHeadingLevel
                        data={{
                            headingLevel: props.data.item.elements.headinglevelsnippet__level,
                            titleText: props.data.item.elements?.title?.value,
                        }}
                    />
                    <p className="description">{props.data.item.elements.description.value}</p>
                    <a href={asset.value[0].url} className="download-item">
                        <p className="file-name">{asset.value[0].name.split('.')[0]}</p>
                        <p className="file-type download-icon">
                            <span>{asset.value[0].name.split('.').pop()}</span>
                            <span>&nbsp;&#xB7;&nbsp;</span>
                            <span>{(asset.value[0].size / 1000).toFixed(2)}KB</span>
                        </p>
                    </a>
                </div>
            )}
        </>
    );
};

export default Download;
