import { ColorThemeEnum } from '../models/enums';

export const getColorThemeFromTagName = (tagName: string): ColorThemeEnum => {
    let colorThemeValue: ColorThemeEnum = ColorThemeEnum.teal;

    switch (tagName) {
        case 'location-tag':
        case 'tutorial-tag':
        case 'guide-tag':
        case 'options-tag':
        case 'success_story-tag':
        case 'news-tag':
        case 'blog-tag':
            colorThemeValue = ColorThemeEnum.blue;
            break;
        case 'tool-tag':
        case 'quiz-tag':
        case 'template-tag':
            colorThemeValue = ColorThemeEnum.teal;
            break;
        case 'date-tag':
        case 'event-tag':
            colorThemeValue = ColorThemeEnum.yellow;
            break;
        case 'organisation-tag':
            colorThemeValue = ColorThemeEnum.green;
            break;
        case 'checklist-tag':
            colorThemeValue = ColorThemeEnum.purple;
            break;
    }
    return colorThemeValue;
};
