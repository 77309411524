import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Error from './page/errorPages/Error';
import Unauthorized from './page/errorPages/Unauthorized';
import Forbidden from './page/errorPages/Forbidden';
import Home from './page/layouts/Home';
import Page from './page/layouts/Page';
import NotFound from './page/errorPages/NotFound';
import { TelemetryProvider } from '@exporter-services/common-ui';
import { PageName } from './models/PageName';

export default function App() {
    return (
        <div id="app" className="ebga">
            <BrowserRouter>
                <TelemetryProvider>
                    <Routes>
                        <Route path="/error" element={<Error />} />
                        <Route path="/find-export-markets/shortlist/:industry/:sector/:market" element={<Page />} />
                        <Route path="/find-export-markets/shortlist-products/:sector/:market" element={<Page />} />
                        <Route
                            path={`/${PageName.LawsAndRegulations}/${PageName.ExportRulesFinder}/${PageName.ProductRequirements}/:sector/:market`}
                            element={<Page />}
                        />
                        <Route
                            path={`/${PageName.LawsAndRegulations}/${PageName.ExportRulesFinder}/${PageName.ProductRequirements}/:sector/:market/${PageName.RulesAndRestrictionsDocument}/:document`}
                            element={<Page />}
                        />
                        <Route path={`/${PageName.PricingCostsAndFinance}/${PageName.FindTariffs}/:third_slug/:sector/:market`} element={<Page />} />
                        <Route path="/:slug/:second_slug/:third_slug/:industry/:sector/:market" element={<Page />} />
                        <Route path="/:slug/:second_slug/:third_slug/:industry/:sector" element={<Page />} />
                        <Route path="/:slug/:second_slug/:third_slug/:industry" element={<Page />} />
                        <Route path="/:slug/:second_slug/:third_slug" element={<Page />} />
                        <Route path="/:slug/:second_slug" element={<Page />} />
                        <Route path="/unauthorized" element={<Unauthorized />} />
                        <Route path="/forbidden" element={<Forbidden />} />
                        <Route path="/:slug" element={<Page />} />
                        <Route path="/" element={<Home />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </TelemetryProvider>
            </BrowserRouter>
        </div>
    );
}
