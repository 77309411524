import React from 'react';
import './Disclaimer.scss';
import { DisclaimerItem } from './DisclaimerItem';
import { Col, Row, Container } from 'reactstrap';
import ResolveLink from '../resolveLink/ResolveLink';

interface DisclaimerProps {
    data: {
        item: DisclaimerItem;
    };
    style?: any;
    image?: any;
    textColor?: any;
}
const Disclaimer = (props: DisclaimerProps) => {
    return (
        <Container className="disclaimer-information" style={props.style}>
            <Row>
                <Col sm="12" md="1" className="disclaimer-image">
                    <img src={props.image} alt="" />
                </Col>
                <Col sm="12" md="11" className="disclaimer-content justify-content-center align-self-center" style={{ color: props.textColor }}>
                    <ResolveLink data={{ item: props.data.item }} type={props.data.item.system.type} />
                </Col>
            </Row>
        </Container>
    );
};

export default Disclaimer;
